export enum ManagementWidgetsPagesEnum {
  TOP_X_GAMES = "top-x-manager",
  MATCH_QUIZ_GAMES = "match-quiz-manager",
  COMPETITION_GAME = "competition-game",
  TEAMS_GAME = "teams-game",
  MATCHES_GAME = "matches-game",
  FANTASY_GAME = "fantasy-game",
  CUSTOM_GAME = "custom-game",
  CLASSIC_QUIZZES = "classic-quiz-manager",
  DISCUSSIONS = "discussions-manager",
  EITHER_OR = "either-or-manager",
  POLL = "poll-manager",
  LIST = "list-manager",
  BRACKET = "bracket-manager",
}

export enum MainPagesEnum {
  DASHBOARD = "dashboard",
  CONFIGURATION = "configuration",
  FEATURES = "features",
  STAFF = "staff",
  REPORTS = "reports",
  API_KEYS = "api-keys",
  EDIT_PROFILE = "edit-profile",
  CHANGE_PASSWORD = "change-password",
}

export enum FansUnitedFeatures {
  PREDICTOR = "predictor",
  TOP_X = "top_x",
  MATCH_QUIZ = "match_quiz",
  LOYALTY = "loyalty",
  WEBHOOK = "webhook",
  CLASSIC_QUIZ = "classic_quiz",
  EITHER_OR = "either_or",
  POLL = "poll",
  BADGES = "badges",
  FOOTBALL_FANTASY = "football_fantasy",
}

export enum PermissionsEnum {
  MANAGE_STAFF = "manage_staff",
  MANAGE_FEATURE_MATCH_QUIZZES = "manage_feature_match_quizzes",
  MANAGE_FEATURE_TOP_X = "manage_feature_top_x",
  MANAGE_FEATURE_PREDICTOR = "manage_feature_predictor",
  MANAGE_FEATURE_LOYALTY = "manage_feature_loyalty",
  MANAGE_FEATURE_EXTERNAL_POINTS = "manage_feature_external_points",
  MANAGE_FEATURE_WEBHOOK = "manage_feature_webhook",
  MANAGE_TOP_X = "manage_top_x",
  MANAGE_MATCH_QUIZZES = "manage_match_quizzes",
  MANAGE_LEADERBOARD_TEMPLATES = "manage_leaderboard_templates",
  MANAGE_MINI_GAMES = "manage_mini_games",
  MANAGE_DISCUSSIONS = "manage_discussions",
  VIEW_REPORTS = "view_reports",
  MANAGE_POLLS = "manage_polls",
  MANAGE_LISTS = "manage_lists",
  MANAGE_CONFIG = "manage_config",
  MANAGE_CUSTOM_GAMES = "manage_custom_games",
}

export enum ERROR_MESSAGES {
  USER_NOT_FOUND = "There is no user record corresponding to this identifier. Please try again.",
  ACCOUNT_TEMPORARILY_DISABLED = "Access to this account has been temporarily disabled due to many failed login attempts. Please try again later.",
  CHANGE_PROFILE_INFO = "There was a problem with updating information. Please try again.",
  PASSWORD_INVALID = "The password is invalid.",
  CURRENT_PASSWORD_INVALID = "The current password is invalid.",
  CHANGE_PASSWORD = "There was a problem with changing password. Please try again.",
  STAFF_CLIENTS = "There was a problem with receiving staff's clients",
  STAFF_MEMBERS = "There was a problem with retrieving staff members for client.",
  DELETE_STAFF_MEMBER = "There was a problem with deleting staff member for client ",
  STAFF_MEMBER_INFO = "There was a problem with fetching staff member's information",
  CLIENT_INFO = "There was a problem with fetching client's information",
  CREATE_STAFF_MEMBER = "There was a problem with creating staff member",
  ROLES_PERMISSIONS = "There was a problem with fetching Fans United's roles permissions",
  USERS_AQUIRED_REPORT = "There was a problem with fetching users aquired report",
  STATS_BASIC_INFO = "There was a problem with feching stats information",
  PREDICTOR_FEATURES = "There was a problem with fetching client's predictor features",
  ENGAGEMENT_COEFFICIENTS_CONFIG = "There was a problem with fetching client's engagement coefficients configuration",
  ENGAGEMENT_COEFFICIENTS_CONFIG_UPDATE = "There was a problem with updating client's engagement coefficients configuration",
  FOOTBALL_DATA = "There was a problem with fetching football's data",
  FEATURES_PREDICTOR_UPDATE = "There was a problem with updating success rate scopes for predictor features",
  FEATURES_TOP_X_UPDATE = "There was a problem with updating top x features",
  FEATURES_MATCH_QUIZ_UPDATE = "There was a problem with updating match quiz features",
  STATS_INFORMATION = "There was a problem with fetching stats information",
  FEATURES_LOYALTY_UPDATE = "There was a problem with updating loyalty features",
  BADGES_UPDATE = "There was a problem with updating badges",
  FEATURES_EXTERNAL_POINTS_UPDATE = "There was a problem with updating external points configuration",
  FEATURES_WEBHOOKS_CONFIG_UPDATE = "There was a problem with updating webhooks configuration",
  FEATURES_ID_VALUES_VALIDATION = "ID value must contain only small English letters, numbers and underscores",
  FEATURES_BADGE_ASSETS_INVALID_URL = "URL for badge assets must be a valid one - starting with http or https and ending with .jpg, .jpeg, .png, .gif, .bmp",
  FEATURES_ID_VALUES_EMPTY = "ID value in each reward should NOT be empty",
  EXTERNAL_POINTS_ID_VALUE_INVALID_LENGTH = "ID value in should be between 0 and 100 (inclusive) characters",
  EXTERNAL_POINTS_ID_VALUE_INVALID_VALUE = "ID value must contain only English letters, numbers and underscores",
  INVALID_POSITIVE_INTEGER_VALUE = "Maximum count value needs to be positive numbers",
  INVALID_INTEGER_VALUE = "Points and maximum count values need to be integers (maximum value is 2 147 483 647)",
  MAU_REPORT = "There was a problem with requesting monthly active users data",
  MAU_VS_REGISTRATIONS_REPORT = "There was a problem with requesting monthly active users vs registrations report data",
  CONSUMPTION_REPORT = "There was a problem with fetching information about plaftorm consumption",
  AVERAGES_REPORT = "There was a problem with fetching averages follows report",
  PREDICTIONS_REPORT = "There was a problem with fetching predictions by period report",
  PREDICTIONS_PER_ENTITY_REPORT = "There was a problem with fetching predictions per entity report",
  ENGAGEMENT_THROUGH_PREDICTIONS = "There was a problem with fetching engagement through predictions data report",
  TOP_FOLLOWED_FOOTBALL_ENTITIES_REPORT = "There was a problem with requesting information about top followed report",
  FOOTBALL_ENTITY_FOLLOWS_REPORT = "There was a problem with receiving information about users follows",
  SUCCESS_RATE_MARKETS_REPORT = "There was a problem with receiving information about markets success rate",
  SUCCESS_RATE_USERS_REPORT = "There was a problem with receiving information about highest success rate users",
  ENGAGEMENT_RFM_GROUP_REPORT = "There was a problem with receiving information about engagement RFM groups",
  ENGAGEMENT_SEGMENTS_REPORT = "There was a problem with receiving information about engagement segments report",
  CACHE_CONFIG = "There was a problem with fetching cache TTL configuration",
  FOOTBALL_SETTINGS_CONFIG = "There was a problem with fetching football settings configuration",
  FOOTBALL_SETTINGS_UPDATE = "There was a problem with updating football settings configuration",
  CACHE_CONFIG_UPDATE = "There was a problem with updating cache TTL",
  CACHE_CONFIG_VALIDATION = "The configured cache TTL can't be less than the minimum cache TTL",
  SUCCESS_RATES_USERS_COUNT = "The provided users count is invalid - the minimum value is 1 and maximum is 1000",
  SUCCESS_RATES_MINIMUM_PREDICTIONS = "The provided minimum predictions is invalid - the minimum value is 0 and maximum is 1000000",
  CLASSIC_QUIZ_FEATURE = "There was a problem with fetching client's Classic Quiz feature",
  CLASSIC_QUIZ_FEATURE_UPDATE = "There was a problem with updating Classic Quiz feature",
  EITHER_OR_FEATURE = "There was a problem with fetching client's Either/Or feature",
  EITHER_OR_FEATURE_UPDATE = "There was a problem with updating Either/Or feature",
  DISCUSSIONS_POSTS_AVERAGES_REPORT = "There was a problem with fetching discussions posts averages report",
  DISCUSSIONS_PERCENTAGE_USERS_WITH_POSTS_REPORT = "There was a problem with fetching discussions percentage users with posts report",
  DISCUSSIONS_TOP_100_CONTRIBUTORS_REPORT = "There was a problem with fetching discussions top 100 contributors report",
  DISCUSSIONS_TOP_100_REPORT = "There was a problem with fetching top 100 discussions report",
  DISCUSSIONS_BREAKDOWN_REPORT = "There was a problem with fetching discussions breakdown report",
  DISCUSSIONS_POSTS_BREAKDOWN_REPORT = "There was a problem with fetching discussions posts breakdown report",
  MINI_GAMES_BREAKDOWN_REPORT = "There was a problem with fetching Mini Games breakdown report",
  MINI_GAMES_POPULAR_REPORT = "There was a problem with fetching Mini Games popular report",
  UPLOAD_IMAGE = "There was a problem with uploading the image",
  SIGNED_IN_URL = "There was a problem with generating signed in URL",
  PRIVATE_LEAGUES_BREAKDOWN_REPORT = "There was a problem with fetching private leagues breakdown report",
  PRIVATE_LEAGUES_TOP_TEMPLATES_REPORT = "There was a problem with fetching private leagues top templates report",
  PRIVATE_LEAGUES_USERS_REPORT = "There was a problem with fetching private leagues users stats report",
  POLL_FEATURE = "There was a problem with fetching client's poll feature",
  POLL_FEATURE_UPDATE = "There was a problem with updating poll feature",
  BADGE_FEATURE = "There was a problem with fetching client's badges",
  GAMES = "There was a problem with fetching client's games",
  FOOTBALL_FANTASY_FEATURE_UPDATE = "There was a problem with updating football fantasy feature",
}

export enum SUCCESS_MESSAGES {
  CHANGE_PASSWORD = "You have successfully changed your password",
  CHANGE_PROFILE_INFO = "You have successfully changed your information",
  DELETE_STAFF_MEMBER = "You have successfully deleted your staff member",
  CHANGE_STAFF_MEMBER_INFO = "You have successfully changed staff member's information",
  CHANGE_STAFF_MEMBER_PASSWORD = "You have successfully changed staff member's password",
  FEATURES_PREDICTOR_UPDATE = "You have successfully updated your success rate scopes",
  FEATURES_TOP_X_UPDATE = "You have successfully updated your top x features",
  FEATURES_MATCH_QUIZ_UPDATE = "You have successfully updated your match quiz features",
  FEATURES_LOYALTY_UPDATE = "You have successfully updated your loyalty features",
  BADGES_UPDATE = "You have successfully updated your badges",
  FEATURES_CACHE_TTL_UPDATE = "You have successfully updated cache TTL",
  FEATURES_EXTERNAL_POINTS_UPDATE = "You have successfully updated your external points configuration",
  FEATURES_WEBHOOKS_CONFIG_UPDATE = "You have successfully updated your webhooks configuration",
  FEATURES_CLASSIC_QUIZ_UPDATE = "You have successfully updated your Classic Quiz feature",
  FEATURES_EITHER_OR_UPDATE = "You have successfully updated your Either/Or feature",
  UPLOAD_IMAGE = "You have successfully uploaded image",
  FOOTBALL_SETTINGS_UPDATE = "You have successfully updated football settings",
  FEATURES_POLL_UPDATE = "You have successfully updated your poll feature",
  FEATURES_FOOTBALL_FANTASY_UPDATE = "You have successfully updated football fantasy feature",
}

export enum PARAGRAPH_MESSAGES {
  DELETE_STAFF_MEMBER_PARAGRAPH = "Are you sure you want to delete ",
}

export enum AuthRequirementEnum {
  FREE = "free",
  LEAD = "lead",
  REGISTERED = "registered",
}
