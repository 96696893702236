import React, { useCallback, useContext } from "react";
import { TopXManager } from "fansunited-widget-top-x";
import { MatchQuizManager } from "fansunited-widget-match-quiz";
import { LeaderboardManager } from "fansunited-widget-leaderboard";
import { ClassicQuizManager } from "fansunited-widget-classic-quiz";
import { DiscussionsManager } from "fansunited-widget-discussions";
import { EitherOrManager } from "fansunited-widget-either-or";
import { PollManager } from "fansunited-widget-poll";
import { ListManager } from "fansunited-widget-list";
import { BracketManager } from "fansunited-widget-bracket";
import "fansunited-widget-top-x/style.css";
import "fansunited-widget-match-quiz/style.css";
import "fansunited-widget-leaderboard/style.css";
import "fansunited-widget-classic-quiz/style.css";
import "fansunited-widget-discussions/style.css";
import "fansunited-widget-either-or/style.css";
import "fansunited-widget-poll/style.css";
import CardWidget from "../Cards/CardWidget";
import { GlobalStatesContext } from "../../providers/GlobalStatesProvider";
import { RouteComponentProps } from "react-router";
import { initApiKey, initEnvironment } from "../../helpers/helpers";
import {
  fuAndSportal365ClientsMap,
  testingClients,
} from "../../constants/constants";

const getSportal365Project = (clientId: string) => {
  if (clientId) {
    return testingClients.includes(clientId)
      ? fuAndSportal365ClientsMap.get("sportal_bg")
      : fuAndSportal365ClientsMap.get(clientId);
  }

  return "";
};

const widgetLeaderboardTypeMap = new Map<string, string>()
  .set("COMPETITION_GAME", "competition")
  .set("TEAMS_GAME", "teams")
  .set("MATCHES_GAME", "matches")
  .set("FANTASY_GAME", "fantasy")
  .set("CUSTOM_GAME", "games");

const ManagementWidget: React.FC = ({ location }: RouteComponentProps) => {
  const { selectedClient } = useContext(GlobalStatesContext);

  const widgetApiKey = initApiKey(selectedClient?.infrastructure || "PROD");
  const widgetClientId = selectedClient.id;
  const widgetEnvironment = initEnvironment(selectedClient.infrastructure);
  const widgetLanguage = "en";
  const sportal365Project = getSportal365Project(selectedClient?.id);
  //@ts-ignore
  const type = location?.state?.type;

  const displayWidget = useCallback(() => {
    switch (type) {
      case "TOP_X":
        return (
          <TopXManager
            key={`${type}-${widgetClientId}`}
            fansUnitedApiKey={widgetApiKey}
            fansUnitedClientId={widgetClientId}
            fansUnitedLanguage={widgetLanguage}
            fansUnitedEnvironment={widgetEnvironment}
            sportal365Project={sportal365Project}
            hideSignOutButton={true}
          />
        );
      case "MATCH_QUIZ":
        return (
          <MatchQuizManager
            key={`${type}-${widgetClientId}`}
            fansUnitedApiKey={widgetApiKey}
            fansUnitedClientId={widgetClientId}
            fansUnitedLanguage={widgetLanguage}
            //@ts-ignore
            fansUnitedEnvironment={widgetEnvironment}
            sportal365Project={sportal365Project}
          />
        );
      case "COMPETITION_GAME":
      case "TEAMS_GAME":
      case "MATCHES_GAME":
      case "FANTASY_GAME":
      case "CUSTOM_GAME":
        return (
          <LeaderboardManager
            key={`${type}-${widgetClientId}`}
            type={widgetLeaderboardTypeMap.get(type)}
            hideSignOutButton={true}
            fansUnitedApiKey={widgetApiKey}
            fansUnitedClientId={widgetClientId}
            fansUnitedLanguage={widgetLanguage}
            fansUnitedEnvironment={widgetEnvironment}
            sportal365Project={sportal365Project}
          />
        );
      case "CLASSIC_QUIZ":
        return (
          <ClassicQuizManager
            key={`${type}-${widgetClientId}`}
            fansUnitedApiKey={widgetApiKey}
            fansUnitedClientId={widgetClientId}
            fansUnitedLanguage={widgetLanguage}
            fansUnitedEnvironment={widgetEnvironment}
            hideSignOutButton={true}
            sportal365Project={sportal365Project}
          />
        );
      case "DISCUSSIONS":
        return (
          <DiscussionsManager
            key={`${type}-${widgetClientId}`}
            fansUnitedApiKey={widgetApiKey}
            fansUnitedClientId={widgetClientId}
            fansUnitedLanguage={widgetLanguage}
            fansUnitedEnvironment={widgetEnvironment}
          />
        );
      case "EITHER_OR":
        return (
          <EitherOrManager
            key={`${type}-${widgetClientId}`}
            fansUnitedApiKey={widgetApiKey}
            fansUnitedClientId={widgetClientId}
            fansUnitedLanguage={widgetLanguage}
            fansUnitedEnvironment={widgetEnvironment}
            hideSignOutButton={true}
            sportal365Project={sportal365Project}
            sportal365Infrastructure="shared"
          />
        );
      case "POLL":
        return (
          <PollManager
            key={`${type}-${widgetClientId}`}
            fansUnitedApiKey={widgetApiKey}
            fansUnitedClientId={widgetClientId}
            fansUnitedLanguage={widgetLanguage}
            fansUnitedEnvironment={widgetEnvironment}
            sportal365Project={sportal365Project}
            hideSignOutButton={true}
          />
        );
      case "LIST":
        return (
          <ListManager
            key={`${type}-${widgetClientId}`}
            fansUnitedApiKey={widgetApiKey}
            fansUnitedClientId={widgetClientId}
            fansUnitedLanguage={widgetLanguage}
            fansUnitedEnvironment={widgetEnvironment}
            hideSignOutButton={true}
          />
        );
      case "BRACKET":
        return (
          <BracketManager
            key={`${type}-${widgetClientId}`}
            fansUnitedApiKey={widgetApiKey}
            fansUnitedClientId={widgetClientId}
            fansUnitedLanguage={widgetLanguage}
            fansUnitedEnvironment={widgetEnvironment}
            sportal365Project={sportal365Project}
            hideSignOutButton={true}
          />
        );
    }
  }, [type, selectedClient]);

  if (!selectedClient && !selectedClient.id) return null;

  return <CardWidget>{displayWidget()}</CardWidget>;
};

export default ManagementWidget;
