export const pageTitles = {
  DASHBOARD: "dashboard",
  CONFIGURATION: "configuration",
  FEATURES: "features",
  STAFF: "staff",
  REPORTS: "reports",
};

export const featuresPages = {
  PREDICTOR: "Predictor",
  TOP_X: "Top X",
  MATCH_QUIZ: "Match Quiz",
  LOYALTY: "Loyalty",
  CACHE_TTL: "Cache TTL",
  EXTERNAL_POINTS: "External points",
  WEBHOOK: "Webhook",
  FOOTBALL_SETTINGS: "Football Settings",
  ENGAGEMENT_COEFFICIENTS: "Engagement Coefficients",
  CLASSIC_QUIZ: "Classic Quiz",
  EITHER_OR: "Either/Or",
  POLL: "Poll",
  BADGES: "Badges",
  FOOTBALL_FANTASY: "Football Fantasy",
};

export const reportsPages = {
  USERS_ACQUIRED: "Users acquired",
  AVERAGES: "Averages",
  CONSUMPTION: "Consumption",
  PREDICTIONS: "Predictions",
  MAU: "Monthly active users",
  FOOTBALL: "Football",
  SUCCESS_RATE: "Success rate",
  ENGAGEMENT: "Engagement",
  PRIVATE_LEAGUES: "Private leagues",
  DISCUSSIONS: "Discussions",
  CLASSIC_QUIZ: "Classic Quiz",
  EITHER_OR: "Either/Or",
};

export const navigations = {
  EDIT: "Edit profile",
  LOGOUT: "Logout",
};

export const widgetNavigationTitles = {
  "top-x-manager": "Top X games",
  "match-quiz-manager": "Match Quiz games",
  "competition-game": "Competition game",
  "teams-game": "Teams game",
  "matches-game": "Matches game",
  "fantasy-game": "Fantasy game",
  "custom-game": "Custom game",
  "classic-quiz-manager": "Classic Quizzes",
  "discussions-manager": "Discussions",
  "either-or-manager": "Either/Or",
  "poll-manager": "Polls",
  "list-manager": "Lists",
  "bracket-manager": "Bracket games"
};

export const urlPathsToPageTitle = {
  dashboard: "dashboard",
  features: "features",
  staff: "staff",
  reports: "reports",
  "top-x-manager": "Manage Top X Games",
  "match-quiz-manager": "Manage Match Quiz Games",
  "leaderboard-manager": "Manage Leaderboard Templates",
  "edit-profile": "Edit profile",
  "change-password": "Change password",
};

export const statsComponentsTypes = {
  TOTAL_USERS: "Users",
  PREDICTIONS_PERIOD: "Predictions",
  AVERAGE_FOLLOWS: "Average follows",
  TOTAL_REQUESTS: "Origin requests",
};

export const statsIcons = {
  TOTAL_USERS: "fas fa-users",
  PREDICTIONS_PERIOD: "fas fa-wand-sparkles",
  AVERAGE_FOLLOWS: "fas fa-plus",
  TOTAL_REQUESTS: "far fa-chart-bar",
};

export const statsIconsColor = {
  TOTAL_USERS: "bg-pink-500",
  PREDICTIONS_PERIOD: "bg-orange-500",
  AVERAGE_FOLLOWS: "bg-brandColor",
  TOTAL_REQUESTS: "bg-red-500",
};

export const pageIcons = {
  dashboard: "fas fa-gauge-high",
  staff: "fas fa-users",
  configuration: "fas fa-sliders",
  features: "fas fa-gear",
  reports: "fas fa-chart-line",
};

export const statsLabels = {
  TOTAL_USERS: "Over the past 30 days",
  PREDICTIONS_PERIOD: "Over the past 30 days",
  AVERAGE_FOLLOWS: "Per user",
  TOTAL_REQUESTS: "For the current month",
};

export const staffRolesDescription: any = {
  client_editor:
    "Client editor can view reports, manage and delete end users and manage games and templates.",
  client_product_manager:
    "Client product manager can view reports, manage features and create templates.",
  client_billing_manager:
    "Client billing manager can manage billing details and invoices.",
  client_admin:
    "Client admin can manage billing details, invoices, staff and end users. He can create Top X, Match Quiz and templates. Can configure features and view reports.",
  service_user:
    "Service user can view all clients, reports (also platform ones) and badges. He can manage ID mapping and cache.",
  platform_operator:
    "Platform operator can view all clients, badges and all kind of reports. He can create and update client, manage billing details, staff, invoices, end users, features, games, templates and ID mapping.",
};

export const rolesMap = new Map<string, string>()
  .set("client_editor", "Client editor")
  .set("client_product_manager", "Client product manager")
  .set("client_admin", "Client admin")
  .set("service_user", "Service user")
  .set("platform_operator", "Platform operator");

export const globalRoles = ["service_user", "platform_operator"];

export const marketMap = new Map<string, string>()
  .set("FT_1X2", "1X2 at full-time")
  .set("HT_1X2", "1X2 at half-time")
  .set("BOTH_TEAMS_SCORE", "Both teams to score")
  .set("HT_FT", "Half-time/Full-time")
  .set("OVER_GOALS_0_5", "Over 0.5 goals in the match")
  .set("OVER_GOALS_1_5", "Over 1.5 goals in the match")
  .set("OVER_GOALS_2_5", "Over 2.5 goals in the match")
  .set("OVER_GOALS_3_5", "Over 3.5 goals in the match")
  .set("OVER_GOALS_4_5", "Over 4.5 goals in the match")
  .set("OVER_GOALS_5_5", "Over 5.5 goals in the match")
  .set("OVER_GOALS_6_5", "Over 6.5 goals in the match")
  .set("OVER_CORNERS_6_5", "Over 6.5 corners in the match")
  .set("OVER_CORNERS_7_5", "Over 7.5 corners in the match")
  .set("OVER_CORNERS_8_5", "Over 8.5 corners in the match")
  .set("OVER_CORNERS_9_5", "Over 9.5 corners in the match")
  .set("OVER_CORNERS_10_5", "Over 10.5 corners in the match")
  .set("OVER_CORNERS_11_5", "Over 11.5 corners in the match")
  .set("OVER_CORNERS_12_5", "Over 12.5 corners in the match")
  .set("OVER_CORNERS_13_5", "Over 13.5 corners in the match")
  .set("DOUBLE_CHANCE", "Double chance")
  .set("PLAYER_SCORE", "Player to score in match")
  .set("PLAYER_YELLOW_CARD", "Yellow card for a player")
  .set("PLAYER_RED_CARD", "Red card for a player")
  .set("RED_CARD_MATCH", "Red card in match")
  .set("PENALTY_MATCH", "Penalty in match")
  .set("PLAYER_SCORE_FIRST_GOAL", "Player to score first goal")
  .set("CORNERS_MATCH", "Exact number of corners in match")
  .set("CORRECT_SCORE", "Correct score")
  .set("CORRECT_SCORE_HT", "Correct score at half-time")
  .set("CORRECT_SCORE_ADVANCED", "Advanced correct score")
  .set("PLAYER_SCORE_HATTRICK", "Player to score a hat-trick")
  .set("PLAYER_SCORE_TWICE", "Player to score twice")
  .set("PLAYER_PERFORMANCE", "Fantasy game player to be used in the squad");

export const marketDescriptionMap = new Map<string, string>()
  .set(
    "FT_1X2",
    "Who will win when the final whistle blows (full-time, 90 minutes) - the home team (1), the away team (2) or it will be a draw (X)."
  )
  .set(
    "HT_1X2",
    "Who will win when the first half finishes - the home team (1), the away team (2) or it will be a draw (X)."
  )
  .set("BOTH_TEAMS_SCORE", "Both teams score at least 1 goal each.")
  .set(
    "HT_FT",
    "Who is winning at half-time and then full-time - the home team (1), the away team (2) or it will be a draw (X)."
  )
  .set("OVER_GOALS_0_5", "Will there be at least 1 goal in the match?")
  .set("OVER_GOALS_1_5", "Will there be at least 2 goals in the match?")
  .set("OVER_GOALS_2_5", "Will there be at least 3 goals in the match?")
  .set("OVER_GOALS_3_5", "Will there be at least 4 goals in the match?")
  .set("OVER_GOALS_4_5", "Will there be at least 5 goals in the match?")
  .set("OVER_GOALS_5_5", "Will there be at least 6 goals in the match?")
  .set("OVER_GOALS_6_5", "Will there be at least 7 goals in the match?")
  .set("OVER_CORNERS_6_5", "Will there be at least 7 corners in the match?")
  .set("OVER_CORNERS_7_5", "Will there be at least 8 corners in the match?")
  .set("OVER_CORNERS_8_5", "Will there be at least 9 corners in the match?")
  .set("OVER_CORNERS_9_5", "Will there be at least 10 corners in the match?")
  .set("OVER_CORNERS_10_5", "Will there be at least 11 corners in the match?")
  .set("OVER_CORNERS_11_5", "Will there be at least 12 corners in the match?")
  .set("OVER_CORNERS_12_5", "Will there be at least 13 corners in the match?")
  .set("OVER_CORNERS_13_5", "Will there be at least 14 corners in the match?")
  .set(
    "DOUBLE_CHANCE",
    "Users can choose two outcomes on how the match will finish - home team will win or draw, away team will win or draw or home team will win or away team will win."
  )
  .set("PLAYER_SCORE", "Will a particular player score a goal in the match?")
  .set(
    "PLAYER_YELLOW_CARD",
    "Will a particular player receive a yellow card in the match?"
  )
  .set(
    "PLAYER_RED_CARD",
    "Will a particular player receive a red card in the match?"
  )
  .set("RED_CARD_MATCH", "Will there be a red card in the match?")
  .set("PENALTY_MATCH", "Will there be a penalty in the match?")
  .set(
    "PLAYER_SCORE_FIRST_GOAL",
    "Which player will score first goal in the match?"
  )
  .set(
    "CORNERS_MATCH",
    "How many corners (exact number) will be played during the match?"
  )
  .set("CORRECT_SCORE", "What will be the correct score at full-time?")
  .set("CORRECT_SCORE_HT", "What will the correct score at half-time?")
  .set(
    "CORRECT_SCORE_ADVANCED",
    "What will be the correct score at full-time? Here the client can specify how many points the user can receive if they have a partially correct prediction. The user will receive points for correctly guessed goal difference, home team goals and away team goals."
  )
  .set(
    "PLAYER_SCORE_HATTRICK",
    "Will a particular player score a hat-trick (3 goals or more)?"
  )
  .set("PLAYER_SCORE_TWICE", "Will a particular player score twice?")
  .set(
    "PLAYER_PERFORMANCE",
    "What player should be selected for the squad? (Fantasy game)"
  );

export const generalPointsMap = new Map<string, string>()
  .set("registration", "User registration")
  .set("add_interest", "User adds interest")
  .set("add_birthdate", "User sets his birthdate")
  .set("add_country", "User sets his country")
  .set("add_gender", "User sets his gender")
  .set("add_avatar", "Users sets his avatar")
  .set("LIKE", "User likes content")
  .set("DISLIKE", "User dislikes content")
  .set("COMMENT", "User leaves a comment")
  .set("SHARE", "User shares content")
  .set("CREATE", "User creates a post in discussion")
  .set("MODERATE", "User's post is moderated")
  .set("REACTION_LIKE", "User received like on a post")
  .set("REACTION_DISLIKE", "User received dislike on a post")
  .set("REACTION_LOVE", "User received love on a post")
  .set("REACTION_LAUGH", "User received laugh on a post")
  .set("REACTION_CARE", "User received care on a post")
  .set("REACTION_WOW", "User received wow on a post")
  .set("REACTION_SAD", "User received sad on a post")
  .set("REACTION_ANGRY", "User received angry on a post")
  .set("POLL_VOTE", "User votes for poll")
  .set("POTM_VOTING", "User votes for 'Player of the match'");

export const averageFollowsTitles = new Map<string, string>()
  .set("average", "Average follows")
  .set("team", "Followed football teams per user")
  .set("player", "Followed football players per user")
  .set("competition", "Followed football competitions per user")
  .set("profile", "Followed profiles per user")
  .set("sport", "Followed sports per user");

export const averageFollowsDescription = new Map<string, string>()
  .set(
    "average",
    "This number represents the average number of follows for your users across all types. This includes football entities, profiles, sports and etc."
  )
  .set(
    "team",
    "How many football teams are followed, on average , by your users?"
  )
  .set(
    "player",
    "How many football players are followed, on average , by your users?"
  )
  .set(
    "competition",
    "How many football competitions are followed, on average , by your users?"
  )
  .set("profile", "How many profiles are followed, on average , by your users?")
  .set("sport", "How many sports are followed, on average , by your users?");

export const averageFollowsIconsMap = new Map<string, string>()
  .set("average", "fas fa-plus")
  .set("competition", "fas fa-trophy")
  .set("team", "fas fa-people-group")
  .set("player", "fas fa-person-running")
  .set("sport", "fas fa-futbol")
  .set("profile", "fas fa-users");

export const averageFollowsColorMap = new Map<string, string>()
  .set("average", "bg-brandColor")
  .set("competition", "bg-yellow-500")
  .set("team", "bg-emerald-500")
  .set("player", "bg-red-500")
  .set("sport", "bg-orange-500")
  .set("profile", "bg-pink-500");

export const selectedPredictionDatasetColor = new Map<string, string>()
  .set("all", "rgb(0, 102, 204)")
  .set("FT_1X2", "rgb(4, 133, 81)")
  .set("HT_1X2", "rgb(245, 105, 66)")
  .set("BOTH_TEAMS_SCORE", "rgb(194, 4, 4)")
  .set("HT_FT", "rgb(9, 173, 20)")
  .set("OVER_GOALS_0_5", "rgb(1, 5, 120)")
  .set("OVER_GOALS_1_5", "rgb(71, 40, 21)")
  .set("OVER_GOALS_2_5", "rgb(208, 212, 4)")
  .set("OVER_GOALS_3_5", "rgb(91, 140, 62)")
  .set("OVER_GOALS_4_5", "rgb(177, 8, 189)")
  .set("OVER_GOALS_5_5", "rgb(67, 0, 156)")
  .set("OVER_GOALS_6_5", "rgb(191, 4, 13)")
  .set("OVER_CORNERS_6_5", "rgb(57, 0, 115)")
  .set("OVER_CORNERS_7_5", "rgb(114, 2, 227)")
  .set("OVER_CORNERS_8_5", "rgb(2, 245, 172)")
  .set("OVER_CORNERS_9_5", "rgb(247, 2, 15)")
  .set("OVER_CORNERS_10_5", "rgb(91, 140, 62)")
  .set("OVER_CORNERS_11_5", "rgb(43, 0, 107)")
  .set("OVER_CORNERS_12_5", "rgb(2, 245, 103)")
  .set("OVER_CORNERS_13_5", "rgb(74, 18, 18)")
  .set("DOUBLE_CHANCE", "rgb(252, 184, 81)")
  .set("PLAYER_SCORE", "rgb(127, 255, 105)")
  .set("PLAYER_YELLOW_CARD", "rgb(84, 170, 255)")
  .set("PLAYER_RED_CARD", "rgb(44, 140, 0)")
  .set("RED_CARD_MATCH", "rgb(173, 162, 36)")
  .set("PENALTY_MATCH", "rgb(94, 167, 255)")
  .set("PLAYER_SCORE_FIRST_GOAL", "rgb(117, 1, 130)")
  .set("CORNERS_MATCH", "rgb(2, 1, 59)")
  .set("CORRECT_SCORE", "rgb(41, 107, 0)")
  .set("CORRECT_SCORE_HT", "rgb(107, 16, 0)")
  .set("CORRECT_SCORE_ADVANCED", "rgb(207, 117, 0)")
  .set("PLAYER_SCORE_HATTRICK", "rgb(6, 0, 128)")
  .set("PLAYER_SCORE_TWICE", "rgb(79, 31, 112)");

export const comparedToPredictionDatasetColor = new Map<string, string>()
  .set("all", "#ed7e17")
  .set("FT_1X2", "#003973")
  .set("HT_1X2", "#09004f")
  .set("BOTH_TEAMS_SCORE", "#301b0d")
  .set("HT_FT", "#1f5400")
  .set("OVER_GOALS_0_5", "#56005c")
  .set("OVER_GOALS_1_5", "#2a0061")
  .set("OVER_GOALS_2_5", "#6b0005")
  .set("OVER_GOALS_3_5", "#3e205c")
  .set("OVER_GOALS_4_5", "#780006")
  .set("OVER_GOALS_5_5", "#37006e")
  .set("OVER_GOALS_6_5", "#008f64")
  .set("OVER_CORNERS_6_5", "#820007")
  .set("OVER_CORNERS_7_5", "#2e4f1b")
  .set("OVER_CORNERS_8_5", "#1a0738")
  .set("OVER_CORNERS_9_5", "#008c3a")
  .set("OVER_CORNERS_10_5", "#571e1e")
  .set("OVER_CORNERS_11_5", "#bf8226")
  .set("OVER_CORNERS_12_5", "#5fab52")
  .set("OVER_CORNERS_13_5", "#27517a")
  .set("DOUBLE_CHANCE", "#265710")
  .set("PLAYER_SCORE", "#635d1a")
  .set("PLAYER_YELLOW_CARD", "#3c5778")
  .set("PLAYER_RED_CARD", "#004080")
  .set("RED_CARD_MATCH", "#615b19")
  .set("PENALTY_MATCH", "#3e6696")
  .set("PLAYER_SCORE_FIRST_GOAL", "#3e0045")
  .set("CORNERS_MATCH", "#0e0d5c")
  .set("CORRECT_SCORE", "#173d00")
  .set("CORRECT_SCORE_HT", "#401008")
  .set("CORRECT_SCORE_ADVANCED", "#734100")
  .set("PLAYER_SCORE_HATTRICK", "#040047")
  .set("PLAYER_SCORE_TWICE", "#2e1440");

export const summaryMap = new Map<string, string>()
  .set("fullTimeOneXTwo", "1X2 at full-time")
  .set("halfTimeOneXTwo", "1X2 at half-time")
  .set("bothTeamsScore", "Both teams to score")
  .set("halfTimeFullTime", "Half-time/Full-time")
  .set("overZeroPointFiveGoals", "Over 0.5 goals in the match")
  .set("overOnePointFiveGoals", "Over 1.5 goals in the match")
  .set("overTwoPointFiveGoals", "Over 2.5 goals in the match")
  .set("overThreePointFiveGoals", "Over 3.5 goals in the match")
  .set("overFourPointFiveGoals", "Over 4.5 goals in the match")
  .set("overFivePointFiveGoals", "Over 5.5 goals in the match")
  .set("overSixPointFiveGoals", "Over 6.5 goals in the match")
  .set("overSixPointFiveCorners", "Over 6.5 corners in the match")
  .set("overSevenPointFiveCorners", "Over 7.5 corners in the match")
  .set("overEightPointFiveCorners", "Over 8.5 corners in the match")
  .set("overNinePointFiveCorners", "Over 9.5 corners in the match")
  .set("overTenPointFiveCorners", "Over 10.5 corners in the match")
  .set("overElevenPointFiveCorners", "Over 11.5 corners in the match")
  .set("overTwelvePointFiveCorners", "Over 12.5 corners in the match")
  .set("overThirteenPointFiveCorners", "Over 13.5 corners in the match")
  .set("doubleChance", "Double chance")
  .set("playerScore", "Player to score in match")
  .set("playerYellowCard", "Yellow card for a player")
  .set("playerRedCard", "Red card for a player")
  .set("redCardMatch", "Red card in match")
  .set("penaltyMatch", "Penalty in match")
  .set("playerScoreFirstGoal", "Player to score first goal")
  .set("cornersMatch", "Exact number of corners in match")
  .set("correctScore", "Correct score")
  .set("correctScoreHalfTime", "Correct score at half-time")
  .set("correctScoreAdvanced", "Advanced correct score")
  .set("playerScoreHattrick", "Player to score a hat-trick")
  .set("playerScoreTwice", "Player to score twice");

export const specialPlayerIdsTitles = new Map<string, string>()
  .set("NOBODY", "There will be no goalscorer in the match")
  .set("OWN_GOAL", "The first goal will be own goal in the match");

export const alternativeAdvanceCorrectScoreTitleMap = new Map<string, string>()
  .set("correctOutcome1X2", "Advanced correct score: 1x2 outcome")
  .set("correctHomeGoals", "Advanced correct score: Home goals")
  .set("correctAwayGoals", "Advanced correct score: Away goals")
  .set("correctGoalDifference", "Advanced correct score: Goal difference");

export const alternativeAdvanceCorrectScoreSnakeCaseMap = new Map<
  string,
  string
>()
  .set("correctOutcome1X2", "CORRECT_OUTCOME_1X2")
  .set("correctHomeGoals", "CORRECT_HOME_GOALS")
  .set("correctAwayGoals", "CORRECT_AWAY_GOALS")
  .set("correctGoalDifference", "CORRECT_GOAL_DIFFERENCE");

export const playerSummaryKeys = [
  "playerScore",
  "playerYellowCard",
  "playerRedCard",
  "playerScoreFirstGoal",
  "playerScoreTwice",
  "playerScoreHattric",
];

export const selectedPeriodDatasetLabel = ": Selected Period";

export const previousPeriodDatasetLabel = ": Previous Period";

export const consumptionSelectedPeriodDatasetColor = new Map<string, string>()
  .set("origin_requests_selected_period", "#ed7e17")
  .set("cdn_requests_selected_period", "#0066cc")
  .set(
    "origin_requests_id-mapping.fansunitedapi.com_selected_period",
    "#010578"
  )
  .set("origin_requests_client.fansunitedapi.com_selected_period", "#472815")
  .set("origin_requests_profile.fansunitedapi.com_selected_period", "#5b8c3e")
  .set("origin_requests_football.fansunitedapi.com_selected_period", "#b108bd")
  .set(
    "origin_requests_prediction.fansunitedapi.com_selected_period",
    "#44009c"
  )
  .set("origin_requests_loyalty.fansunitedapi.com_selected_period", "#bf040d")
  .set("origin_requests_reporting.fansunitedapi.com_selected_period", "#3a0073")
  .set("cdn_requests_id-mapping.fansunitedapi.com_selected_period", "#bf040d")
  .set("cdn_requests_client.fansunitedapi.com_selected_period", "#7302e3")
  .set("cdn_requests_profile.fansunitedapi.com_selected_period", "#02f5ac")
  .set("cdn_requests_football.fansunitedapi.com_selected_period", "#f7020e")
  .set("cdn_requests_prediction.fansunitedapi.com_selected_period", "#5b8c3e")
  .set("cdn_requests_loyalty.fansunitedapi.com_selected_period", "#2b006b")
  .set("cdn_requests_reporting.fansunitedapi.com_selected_period", "#02f567")
  .set("id-mapping.fansunitedapi.com_selected_period", "#4a1212")
  .set("client.fansunitedapi.com_selected_period", "#fcb851")
  .set("profile.fansunitedapi.com_selected_period", "#7fff69")
  .set("football.fansunitedapi.com_selected_period", "#54aaff")
  .set("prediction.fansunitedapi.com_selected_period", "#2c8c00")
  .set("loyalty.fansunitedapi.com_selected_period", "#ada224")
  .set("reporting.fansunitedapi.com_selected_period", "#5ea6ff");

export const consumptionPreviousPeriodDatasetColor = new Map<string, string>()
  .set("origin_requests_previous_period", "#ef4444")
  .set("cdn_requests_previous_period", "#10b981")
  .set(
    "origin_requests_id-mapping.fansunitedapi.com_previous_period",
    "#09004f"
  )
  .set("origin_requests_client.fansunitedapi.com_previous_period", "#301b0d")
  .set("origin_requests_profile.fansunitedapi.com_previous_period", "#1f5400")
  .set("origin_requests_football.fansunitedapi.com_previous_period", "#56005c")
  .set(
    "origin_requests_prediction.fansunitedapi.com_previous_period",
    "#2a0061"
  )
  .set("origin_requests_loyalty.fansunitedapi.com_previous_period", "#6b0005")
  .set("origin_requests_reporting.fansunitedapi.com_previous_period", "#3e205c")
  .set("cdn_requests_id-mapping.fansunitedapi.com_previous_period", "#780006")
  .set("cdn_requests_client.fansunitedapi.com_previous_period", "#37006e")
  .set("cdn_requests_profile.fansunitedapi.com_previous_period", "#008f64")
  .set("cdn_requests_football.fansunitedapi.com_previous_period", "#820007")
  .set("cdn_requests_prediction.fansunitedapi.com_previous_period", "#2e4f1b")
  .set("cdn_requests_loyalty.fansunitedapi.com_previous_period", "#1a0738")
  .set("cdn_requests_reporting.fansunitedapi.com_previous_period", "#008c3a")
  .set("id-mapping.fansunitedapi.com_previous_period", "#571e1e")
  .set("client.fansunitedapi.com_previous_period", "#bf8226")
  .set("profile.fansunitedapi.com_previous_period", "#5fab52")
  .set("football.fansunitedapi.com_previous_period", "#27517a")
  .set("prediction.fansunitedapi.com_previous_period", "#265710")
  .set("loyalty.fansunitedapi.com_previous_period", "#635d1a")
  .set("reporting.fansunitedapi.com_previous_period", "#3c5778");

export const widgetPagePathTypeMap = new Map<string, string>()
  .set("top-x-manager", "TOP_X")
  .set("match-quiz-manager", "MATCH_QUIZ")
  .set("competition-game", "COMPETITION_GAME")
  .set("teams-game", "TEAMS_GAME")
  .set("matches-game", "MATCHES_GAME")
  .set("fantasy-game", "FANTASY_GAME")
  .set("custom-game", "CUSTOM_GAME")
  .set("classic-quiz-manager", "CLASSIC_QUIZ")
  .set("discussions-manager", "DISCUSSIONS")
  .set("either-or-manager", "EITHER_OR")
  .set("poll-manager", "POLL")
  .set("list-manager", "LIST")
  .set("bracket-manager", "BRACKET");

export const localstorageKey = "fu_management_portal";

export const ApiIdToNameMap = new Map<string, string>()
  .set("com.fansunited.profile", "Profile API")
  .set("com.fansunited.prediction", "Prediction API")
  .set("com.fansunited.loyalty", "Loyalty API")
  .set("com.fansunited.discussions", "Discussions API")
  .set("com.fansunited.minigames", "Mini-Games API")
  .set("com.fansunited.voting", "Voting API")
  .set("com.fansunited.client", "Client API");

export const endpointToApi = new Map<string, string>()
  .set("/clients/{client_id}/lists/{list_id}/content", "com.fansunited.client")
  .set("/countries", "com.fansunited.profile")
  .set("/profiles", "com.fansunited.profile")
  .set("/profiles/{userId}", "com.fansunited.profile")
  .set("/profiles/detailed", "com.fansunited.profile")
  .set("/profile/{profile_id}/badges", "com.fansunited.profile")
  .set("/profile/{userId}/followers", "com.fansunited.profile")
  .set("/profile/{userId}/following", "com.fansunited.profile")
  .set("/games", "com.fansunited.prediction")
  .set("/games/{game_id}", "com.fansunited.prediction")
  .set("/games/{game_id}/correct-results", "com.fansunited.prediction")
  .set("/games/{game_id}/predictions", "com.fansunited.prediction")
  .set("/games/{game_id}/results", "com.fansunited.prediction")
  .set("/predictions/summary/{match_id}", "com.fansunited.prediction")
  .set("/predictions/users/{user_id}", "com.fansunited.prediction")
  .set("/predictions/{prediction_id}", "com.fansunited.prediction")
  .set("/custom/bracket", "com.fansunited.prediction")
  .set("/custom/bracket/{bracket_id}", "com.fansunited.prediction")
  .set("/custom/bracket/{game_id}/predictions", "com.fansunited.prediction")
  .set("/custom/bracket/{game_id}/ranking", "com.fansunited.prediction")
  .set("/users/{profile_id}/activities", "com.fansunited.loyalty")
  .set("/leaderboard/games/{game_id}", "com.fansunited.loyalty")
  .set("/leaderboard/users/{user_id}/rankings", "com.fansunited.loyalty")
  .set("/leaderboard/{template_id}", "com.fansunited.loyalty")
  .set("/leaderboard/templates", "com.fansunited.loyalty")
  .set("/leaderboard/templates/{template_id}", "com.fansunited.loyalty")
  .set("/profile/{user_id}/engagement", "com.fansunited.loyalty")
  .set("/users/{profile_id}/statistics", "com.fansunited.loyalty")
  .set("/statistics/top", "com.fansunited.loyalty")
  .set("/statistics/follows", "com.fansunited.loyalty")
  .set("/posts/users/{user_id}", "com.fansunited.discussions")
  .set("/discussions/top", "com.fansunited.discussions")
  .set("/discussions/posts/count", "com.fansunited.discussions")
  .set("/discussions/users/{user_id}/ban", "com.fansunited.discussions")
  .set("/classic-quizzes", "com.fansunited.minigames")
  .set("/classic-quizzes/{classic_quiz_id}", "com.fansunited.minigames")
  .set("/classic-quizzes/rank", "com.fansunited.minigames")
  .set("/classic-quizzes/user-rank", "com.fansunited.minigames")
  .set(
    "/classic-quizzes/{classic_quiz_id}/results-summary",
    "com.fansunited.minigames"
  )
  .set("/either-or", "com.fansunited.minigames")
  .set("/either-or/{either_or_id}", "com.fansunited.minigames")
  .set("/either-or/{either_or_id}/results", "com.fansunited.minigames")
  .set("/football/potm/{match_id}", "com.fansunited.voting")
  .set("/polls", "com.fansunited.voting")
  .set("/polls/{poll_id}", "com.fansunited.voting")
  .set("/polls/{poll_id}/votes", "com.fansunited.voting")
  .set("/polls/votes", "com.fansunited.voting");

export const engagementSinglePredictionsKeyToContent = new Map<string, string>()
  .set("atLeastOne", "At least 1 prediction")
  .set("atLeastFive", "At least 5 predictions")
  .set("atLeastTen", "At least 10 predictions")
  .set("atLeastFifty", "At least 50 predictions")
  .set("atLeastHundred", "At least 100 predictions");

export const engagementGamesPredictionsKeyToContent = new Map<string, string>()
  .set("atLeastOne", "At least 1 game participation")
  .set("atLeastThree", "At least 3 game participations")
  .set("atLeastFive", "At least 5 game participations");

export const usersBreakdownDefault = ["all"];

export const testingClients = [
  "automationtesting",
  "demo",
  "productiontesting1",
  "productiontesting2",
  "watg_test",
];

export const fuAndSportal365ClientsMap = new Map<string, string>()
  .set("sportal_bg", "sportal.bg")
  .set("abola_pt", "abola.pt")
  .set("gsp_ro", "gsp.ro")
  .set("sport_sk", "sport.aktuality.sk")
  .set("sportal_gr", "sportal.gr")
  .set("sportal_hu", "sportal.blikk.hu")
  .set("sportal_rs", "sportal.blic.rs")
  .set("winbet_bg", "winbet.bg");
