import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  widgetNavigationTitles,
  widgetPagePathTypeMap,
} from "../../../constants/constants";
import { ManagementWidgetsPagesEnum } from "../../../enums/enums";
import { iconStyle, linkStyle } from "../../../helpers/helpers";
import { UserAccessPagesContext } from "../../../providers/UserAccessPagesProvider";

const WidgetIcon = (widget: string) => {
  let icon: string = "";

  if (widget === ManagementWidgetsPagesEnum.TOP_X_GAMES) icon = "fa-gem";
  else if (widget === ManagementWidgetsPagesEnum.MATCH_QUIZ_GAMES)
    icon = "fa-dice";
  else if (
    widget === ManagementWidgetsPagesEnum.COMPETITION_GAME ||
    widget === ManagementWidgetsPagesEnum.TEAMS_GAME ||
    widget === ManagementWidgetsPagesEnum.MATCHES_GAME ||
    widget === ManagementWidgetsPagesEnum.FANTASY_GAME ||
    widget === ManagementWidgetsPagesEnum.CUSTOM_GAME
  )
    icon = "fa-trophy";
  else if (widget === ManagementWidgetsPagesEnum.CLASSIC_QUIZZES)
    icon = "fa-question";
  else if (widget === ManagementWidgetsPagesEnum.DISCUSSIONS)
    icon = "fa-comments";
  else if (widget === ManagementWidgetsPagesEnum.EITHER_OR)
    icon = "fa-arrows-alt-h";
  else if (widget === ManagementWidgetsPagesEnum.POLL) icon = "fa-poll";
  else if (widget === ManagementWidgetsPagesEnum.LIST) icon = "fa-list";
  else if (widget === ManagementWidgetsPagesEnum.BRACKET) icon = "fa-trophy";

  return <i className={iconStyle(icon, widget)}></i>;
};

type WidgetsManagementProps = {
  onClickNavigationUrlHideMobileMenu: () => void;
};

const WidgetsManagement: React.FC<WidgetsManagementProps> = ({
  onClickNavigationUrlHideMobileMenu,
}) => {
  const { pages } = useContext(UserAccessPagesContext);

  const allManagePages: string[] = Object.values(ManagementWidgetsPagesEnum);
  let managePages = pages.filter((page: string) =>
    allManagePages.includes(page)
  );

  return (
    <>
      {/* Heading */}
      <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
        Manage
      </h6>
      {/* Navigation */}
      <ul className="md:min-w-full flex flex-col list-none md:mb-3">
        {managePages.map((widgetPage: string, index: number) => (
          <li key={`widget-key-${index}`} className="inline-flex inline-block">
            <Link
              className={linkStyle(widgetPage)}
              to={{
                pathname: `/${widgetPage}`,
                state: {
                  type: widgetPagePathTypeMap.get(widgetPage),
                },
              }}
              onClick={onClickNavigationUrlHideMobileMenu}
            >
              {WidgetIcon(widgetPage)}
              <span>
                {
                  widgetNavigationTitles[
                    widgetPage as keyof typeof widgetNavigationTitles
                  ]
                }
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default WidgetsManagement;
